<template>
  <div>
    <div class="p-2 card">
      <b-row>
        <b-col lg="8">
          <h2>Novo questionário</h2>
          <div class="mb-2">
              Para criar um novo questionário você deve preencher as informações abaixo:
          </div>
        </b-col>
        <b-col>
          <b-row class="d-flex flex-row-reverse align-items-center mr-1" >
              <img
                :src="require('@/assets/custom-icons/info.svg')"
                alt="icone de informações"
                @click="infoQuestionario"
                class="cursor-pointer with-information-cora"
              >
              <span class="mr-1">TUTORIAL CORA</span>
          </b-row>
        </b-col>
      </b-row>
      <b-overlay :show="loadingPage" rounded="lg" opacity="0.7">
        <validation-observer ref="simpleRules">
          <b-row>
            <b-col md="6" class="mb-1">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label="Nome do Questionário*"
                  label-for="input-nome"
                >
                  <b-form-input
                    v-model="form.questionario.nome"
                    :state="errors.length > 0 ? false : null"
                    id="input-nome"
                    placeholder="(máx. 50 caracteres)"
                    maxlength="50"
                    autocomplete="off"
                    v-on:focusout="verificaNomeEmUso()"
                  />
                  <small v-if="errors.length > 0" class="text-danger">
                    Campo Obrigatório
                  </small>
                  <small v-if="erroNomeQuestionario" class="text-danger">
                    O nome escolhido já está em uso. Por favor, escolha um diferente.
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" class="mb-1">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label="Descrição*"
                  label-for="input-descricao"
                >
                  <b-form-input
                    v-model="form.questionario.descricao"
                    :state="errors.length > 0 ? false : null"
                    id="input-descricao"
                    placeholder="(máx. 115 caracteres)"
                    maxlength="115"
                    autocomplete="off"
                  />
                  <small v-if="errors.length > 0" class="text-danger">
                    Campo Obrigatório
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center justify-content-between mt-2">
            <b-col lg="6">
               <h2>Cadastrar perguntas</h2>
            </b-col>
            <b-col lg="3" class="adjust-position">
              <div>
                <b-form-radio
                  v-model="form.questionario.usarFeedback"
                  name="some-radios"
                  :value="true"
                  class="title-radio-custom"
                >
                  Utilizar feedback
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="3" class="adjust-position">
              <div>
                <b-form-radio
                  v-model="form.questionario.usarFeedback"
                  name="some-radios"
                  :value="false"
                  class="title-radio-custom"
                >
                  Não utilizar feedback
                </b-form-radio>
              </div>
            </b-col>
          </b-row>
          <div v-if="form.questionario.usarFeedback">
            <div v-for="n in form.questionario.qtdQuestoes">
              <b-row class="mb-1 mt-1">
                <b-col md="6">
                  <small> Questão {{n}} </small><br>
                  <small class="custom-information"> Mínimo de 1 e máximo de 50 questões </small>
                </b-col>
              </b-row>
              <div class="ml-1 mb-3">
                <b-row>
                  <b-form-checkbox
                    v-model="form.questionario.questoes[n-1].tipoSelecao.selecaoMultipla"
                     @change="onChange('selecaoMultipla', n-1)"
                  />
                  <small> Seleção múltipla </small>
                  <b-form-checkbox
                    v-model="form.questionario.questoes[n-1].tipoSelecao.multiplaEscolha"
                    class="ml-1"
                    @change="onChange('multiplaEscolha', n-1)"
                  />
                  <small> Escolha única </small>
                </b-row>
                <b-row v-if="!form.questionario.questoes[n-1].tipoSelecao.selecaoMultipla && !form.questionario.questoes[n-1].tipoSelecao.multiplaEscolha">
                  <small class="text-danger mt-1"> Selecione uma opção </small>
                </b-row>
                <b-row v-if="form.questionario.usarFeedback" class="d-flex flex-row-reverse">
                  <b-col lg="12" align="right" class="align-items-center">
                    <small
                      class="cursor-pointer info-pontuacao"
                      @click="infoPontuacao()"
                    >
                      Peso total da questão: 36
                    </small>
                    <img
                      :src="require('@/assets/custom-icons/info.svg')"
                      alt="icone de informações"
                      @click="infoQuestionario"
                      class="cursor-pointer with-information-cora"
                    >
                  </b-col>
                </b-row>
              </div>
              <validation-provider #default="{ errors }" rules="required">
                <b-input-group class="mt-0" :id="labelInputQuestao+n">
                  <template #append>
                    <b-input-group-text
                      @click.prevent="removeQuestao(n-1)"
                      class="cursor-pointer"
                      :disabled="form.questionario.qtdQuestoes > 1 ? false : true"
                      :style="errors.length > 0 ? 'border: 1px solid red' : null"
                    >
                      <img :src="require('@/assets/custom-icons/trash.svg')" alt="">
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="form.questionario.questoes[n-1].enunciado"
                    placeholder="Digite aqui o enunciado* (máx. 115 caracteres)"
                    autocomplete="off"
                    maxlength="115"
                    class="placeholder-black placeholder-bold"
                    :style="errors.length > 0 ? 'border: 1px solid red' : null"
                  />
                </b-input-group>
                <small v-if="errors.length > 0" class="text-danger ml-1">
                  Campo Obrigatório
                </small>
              </validation-provider>
              <div v-for="x in form.questionario.questoes[n-1].qtdAlternativas">
                <validation-provider #default="{ errors }" rules="required">
                  <b-input-group class="mt-1">
                    <template #append>
                      <b-input-group-text
                        @click.prevent="removeAlternativa(n-1, x-1)"
                        :disabled="form.questionario.questoes[n-1].desabilitaRemoveAlternativas"
                        class="cursor-pointer"
                        style="border: none;"
                      >
                        <img :src="require('@/assets/custom-icons/trash.svg')" alt="">
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="form.questionario.questoes[n-1].alternativas[x-1].opcao"
                      :placeholder="labelPlaceholder+labelPlaceholderAlternativas[x]+')'"
                      autocomplete="off"
                      class="placeholder-black mr-1 rounded width-input-custom"
                      :style="errors.length > 0 ? 'border: 1px solid red;' : ''"
                      maxlength="115"
                    />
                    <b-form-input
                      v-model="form.questionario.questoes[n-1].alternativas[x-1].peso"
                      class="rounded"
                      :style="!form.questionario.questoes[n-1].alternativas[x-1].peso > 0 || form.questionario.questoes[n-1].alternativas[x-1].peso == 0? 'border: 1px solid red; width:5%;' : 'width:5%;'"
                      type="number"
                      placeholder="Peso"
                      autocomplete="off"
                      @keyup="somaPontuacaoTotal(n-1)"
                    />
                  </b-input-group>
                </validation-provider>
                <b-col v-if="!form.questionario.questoes[n-1].alternativas[x-1].peso" align="right">
                    <small class="text-danger mr-1">
                      Campo Obrigatório
                    </small>
                </b-col>
                <b-col v-if="form.questionario.questoes[n-1].alternativas[x-1].peso && form.questionario.questoes[n-1].alternativas[x-1].peso == 0" align="right">
                    <small class="text-danger mr-1">
                      Valor Inválido
                    </small>
                </b-col>
              </div>
              <small class="custom-information"> Mínimo de 2 e máximo de 8 alternativas </small>
              <small v-if="form.questionario.questoes[n-1].pontuacaoTotal != 36" class="text-danger ml-1"><br>
                Soma total da pontuação é diferente de 36.
              </small>
              <div class="mt-1">
                <b-button
                  type="submit"
                  variant="custom"
                  class="min-button"
                  @click.prevent="insereAlternativa(n-1)"
                  :disabled="form.questionario.questoes[n-1].qtdAlternativas <= 7 ? false : true"
                >
                  +
                </b-button>
                <small class="placeholder-black ml-custom">
                  Inserir mais alternativas
                </small>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="n in form.questionario.qtdQuestoes">
              <b-row class="mb-1 mt-1">
                <b-col md="6">
                  <small> Questão {{n}} </small><br>
                  <small class="custom-information"> Mínimo de 1 e máximo de 50 questões </small>
                </b-col>
              </b-row>
              <div class="ml-1 mb-3">
                <b-row>
                  <b-form-checkbox
                    v-model="form.questionario.questoes[n-1].tipoSelecao.selecaoMultipla"
                     @change="onChange('selecaoMultipla', n-1)"
                  />
                  <small> Seleção múltipla </small>
                  <b-form-checkbox
                    v-model="form.questionario.questoes[n-1].tipoSelecao.multiplaEscolha"
                    class="ml-1"
                    @change="onChange('multiplaEscolha', n-1)"
                  />
                  <small> Escolha única </small>
                </b-row>
                <b-row v-if="!form.questionario.questoes[n-1].tipoSelecao.selecaoMultipla && !form.questionario.questoes[n-1].tipoSelecao.multiplaEscolha">
                  <small class="text-danger mt-1"> Selecione uma opção</small>
                </b-row>
              </div>
              <validation-provider #default="{ errors }" rules="required">
                <b-input-group class="mt-1" :id="labelInputQuestao+n">
                  <template #append>
                    <b-input-group-text
                      @click.prevent="removeQuestao(n-1)"
                      class="cursor-pointer"
                      :disabled="form.questionario.qtdQuestoes > 1 ? false : true"
                      :style="errors.length > 0 ? 'border: 1px solid red' : null"
                    >
                      <img :src="require('@/assets/custom-icons/trash.svg')" alt="">
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="form.questionario.questoes[n-1].enunciado"
                    placeholder="Digite aqui o enunciado* (máx. 115 caracteres)"
                    autocomplete="off"
                    maxlength="115"
                    class="placeholder-black placeholder-bold"
                    :style="errors.length > 0 ? 'border: 1px solid red' : null"
                  />
                </b-input-group>
                <small v-if="errors.length > 0" class="text-danger ml-1">
                  Campo Obrigatório
                </small>
              </validation-provider>
              <div v-for="x in form.questionario.questoes[n-1].qtdAlternativas">
                <validation-provider #default="{ errors }" rules="required">
                  <b-input-group class="mt-1" id="teste">
                    <template #append>
                      <b-input-group-text
                        @click.prevent="removeAlternativa(n-1, x-1)"
                        class="cursor-pointer"
                        :disabled="form.questionario.questoes[n-1].desabilitaRemoveAlternativas"
                        :style="errors.length > 0 ? 'border: 1px solid red' : null"
                      >
                        <img :src="require('@/assets/custom-icons/trash.svg')" alt="">
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="form.questionario.questoes[n-1].alternativas[x-1].opcao"
                      :placeholder="labelPlaceholder+labelPlaceholderAlternativas[x]+')'"
                      autocomplete="off"
                      maxlength="115"
                      class="placeholder-black"
                      :style="errors.length > 0 ? 'border: 1px solid red' : null"
                    />
                  </b-input-group>
                  <small v-if="errors.length > 0" class="text-danger ml-1">
                    Campo Obrigatório
                  </small>
                </validation-provider>
              </div>
              <small class="custom-information"> Mínimo de 2 e máximo de 8 alternativas </small>
              <div class="mt-1">
                <b-button
                  type="submit"
                  variant="custom"
                  class="min-button"
                  @click.prevent="insereAlternativa(n-1)"
                  :disabled="form.questionario.questoes[n-1].qtdAlternativas <= 7 ? false : true"
                >
                  +
                </b-button>
                <small class="placeholder-black ml-custom">
                  Inserir mais alternativas
                </small>
              </div>
            </div>
          </div>
          <b-row class="mt-1">
            <b-col md="6" class="mb-1">
              <b-button
                type="submit"
                variant="custom"
                class="mt-1 min-button"
                @click.prevent="inserirQuestao()"
                :disabled="form.questionario.qtdQuestoes>=50 ? true : false"
              >
                +
              </b-button>
              <small class="placeholder-black ml-custom">
                <strong>Inserir nova questão</strong>
              </small>
            </b-col>
          </b-row>
          <b-row class="botoes">
            <b-col class="align-custom-buttons">
              <b-button
                v-if="!inverterCancelar"
                type="submit"
                variant="outline-secondary"
                class="mt-1 mr-1"
                @click.prevent="returnToList()"
              >
                <span>
                  Cancelar
                </span>
              </b-button>
              <b-button
                type="submit"
                variant="custom"
                class="mt-1"
                @click.prevent="submitForm()"
              >
                <span>
                  Salvar questões cadastradas
                </span>
              </b-button>
              <b-button
                v-if="inverterCancelar"
                type="submit"
                variant="outline-secondary"
                class="mt-1"
                @click.prevent="returnToList()"
              >
                <span>
                  Cancelar
                </span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-overlay>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
  import { required } from "@validations";
  import vSelect from "vue-select";
  import {
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BCardText,
    BFormRadio,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BOverlay,
    BInputGroup,
    BInputGroupText,
    BInputGroupAppend,
    BImg
  } from "bootstrap-vue";
  import arquivoPdf from "@/assets/manual-uso-aplicao-questionarios/manual-cora-v-1.pdf";
  import { modalGenericModel } from '@/libs/sweetalerts';

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      required,
      vSelect,
      BFormDatepicker,
      BFormTimepicker,
      BFormTextarea,
      BFormRadio,
      BFormInput,
      BRow,
      BCol,
      BFormGroup,
      BFormCheckbox,
      BButton,
      BOverlay,
      BInputGroup,
      BInputGroupText,
      BInputGroupAppend,
      BCardText,
      AppBreadcrumb,
      BImg
    },

    data() {
      return {
        loadingPage: true,
        inverterCancelar: true,
        labelInputQuestao: 'input-enunciado-questao-',
        labelPlaceholder: 'Digite aqui a alternativa ',
        erroNomeQuestionario: false,
        form:{
          questionario: {
            nome: null,
            descricao: null,
            usarFeedback: false,
            qtdQuestoes: 1,
            questoes: [{
              qtdAlternativas: 2,
              desabilitaRemoveAlternativas: true,
              enunciado: null,
              pontuacaoTotal: 0,
              tipoSelecao: {
                selecaoMultipla: false,
                multiplaEscolha: false
              },
              alternativas: [
                {
                  opcao: null,
                  peso: null
                },
                {
                  opcao: null,
                  peso: null
                }
              ]
            }],
            feedbacks: [
              {
                valorInicial: null,
                valorFinal: null,
                mensagem: null
              }
            ]
          }
        },
        labelPlaceholderAlternativas:{
          1: 'a',
          2: 'b',
          3: 'c',
          4: 'd',
          5: 'e',
          6: 'f',
          7: 'g',
          8: 'h',
        }
      };
    },

    props: {
      questionarioData: {
        type: Object,
        required: false
      },
    },

    async mounted(){
      await this.carregaQuestionario();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.loadingPage = false;
    },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

    methods: {

      handleResize() {
        if(window.innerWidth <= 500){
          this.inverterCancelar = true;
        }else{
          this.inverterCancelar = false;
        }
      },

      carregaQuestionario(){
        if(this.questionarioData){
          this.form.questionario.nome      = this.questionarioData.nome;
          this.form.questionario.descricao = this.questionarioData.descricao;
          this.form.questionario.usarFeedback = this.questionarioData.usarFeedback;
          this.form.questionario.qtdQuestoes = this.questionarioData.questoes.length;
          this.form.questionario.questoes = this.questionarioData.questoes;
          this.form.questionario.feedbacks = this.questionarioData.feedbacks;
        }
      },

      onChange(tipoSelecao, n){
        if(tipoSelecao === 'selecaoMultipla'){
          this.form.questionario.questoes[n].tipoSelecao.selecaoMultipla = true;
          this.form.questionario.questoes[n].tipoSelecao.multiplaEscolha = false;
        }else if(tipoSelecao === 'multiplaEscolha'){
          this.form.questionario.questoes[n].tipoSelecao.selecaoMultipla = false;
          this.form.questionario.questoes[n].tipoSelecao.multiplaEscolha = true;
        }else{
          this.form.questionario.questoes[n].tipoSelecao.selecaoMultipla = null;
          this.form.questionario.questoes[n].tipoSelecao.multiplaEscolha = null;
        }
      },

      infoQuestionario(){
        modalGenericModel(
          'Instruções',
          'Mais instruções sobre como preencher o questionário',
          require('@/assets/custom-icons/info-gray.svg'),
          'Abrir PDF',
          null,
          true
        ).then((result) => {
          if(result.isConfirmed){
            window.open(arquivoPdf, '_blank');
          }else{
            return;
          }
        });
      },

      infoPontuacao(){
        modalGenericModel(
          'Incluir pontuação',
          'É necessário incluir a pontuação em todas as alternativas' +
          ' cadastradas antes de continuar. A pontuação de todas as' +
          ' alternativas somadas deve ser igual a 36',
          require('@/assets/custom-icons/info-gray.svg'),
          'Entendi',
          null,
          true
        );
      },

      inserirQuestao(){
        if(this.form.questionario.qtdQuestoes<=50){
          let questao =  {
            qtdAlternativas: 2,
            enunciado: null,
            pontuacaoTotal: 0,
            feedback: {
              intervalo: {
                valorInicial: null,
                valorFinal: null,
                mensagem: null
              }
            },
            tipoSelecao: {
              selecaoMultipla: false,
              multiplaEscolha: false
            },
            alternativas: [
              {
                opcao: null,
                peso: null
              },
              {
                opcao: null,
                peso: null
              }
            ]
          };
          this.form.questionario.questoes.push(questao);
          this.form.questionario.qtdQuestoes++;
        }
      },

      removeQuestao(questao){
        if(this.form.questionario.qtdQuestoes>1){
          this.form.questionario.questoes.splice(questao,1);
          this.form.questionario.qtdQuestoes--;
        }
      },

      insereAlternativa(questao){
        if(this.form.questionario.questoes[questao].qtdAlternativas<=7){
          let opcao = {opcao:null};
          this.form.questionario.questoes[questao].alternativas.push(opcao);
          this.form.questionario.questoes[questao].qtdAlternativas++;
          this.form.questionario.questoes[questao].desabilitaRemoveAlternativas = false;
        }
      },

      removeAlternativa(questao, alternativa){
        if(this.form.questionario.questoes[questao].qtdAlternativas>2){
          this.form.questionario.questoes[questao].alternativas.splice(alternativa, 1);
          this.form.questionario.questoes[questao].qtdAlternativas--;
          this.somaPontuacaoTotal(questao);
        }else{
          this.form.questionario.questoes[questao].desabilitaRemoveAlternativas = true;
        }
      },

      somaPontuacaoTotal(n){
        let total = 0;
        this.form.questionario.questoes[n].alternativas.forEach(alternativa => {
          if(alternativa.peso != null){
            total = parseInt(total) + parseInt(alternativa.peso);
          }
        });
        this.form.questionario.questoes[n].pontuacaoTotal = total;
      },

      returnToList(){
        let route = 'listagem-questionarios';
        this.$router.push({ name: route });
      },


      async submitForm(){
        this.loadingPage = true;
        let vazio = false;

        await this.verificaNomeEmUso();

        vazio = this.verificaSubmitForm(vazio);

        this.verificaSubmitFormVazio(vazio)
      },

      verificaSubmitForm(vazio){
        if(this.erroNomeQuestionario){
          vazio = true;
        }

        if(this.form.questionario.usarFeedback){
          vazio = this.verificacaoIteracaoSubmitForm(vazio)
        }

        for (let value of this.form.questionario.questoes) {
          if(!value.tipoSelecao.selecaoMultipla && !value.tipoSelecao.multiplaEscolha){
            vazio = true;
          }
        }

        return vazio
      },

      verificacaoIteracaoSubmitForm(vazio){
        let a = this.form.questionario.questoes.length;
        let b = 0;
        for(let c = 0; c < a; c++){
          b = this.form.questionario.questoes[c].alternativas.length;
          for(let d = 0; d < b; d++){
            if(
              !this.form.questionario.questoes[c].alternativas[d].peso ||
              this.form.questionario.questoes[c].alternativas[d].peso == 0 ||
              this.form.questionario.questoes[c].pontuacaoTotal !=36
            ){
              return true;
            }
          }
        }

        return vazio;
      },

      verificaSubmitFormVazio(vazio){
        if(vazio){
          modalGenericModel(
            'Confira o preenchimento correto dos campos',
            '',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Ok'
          );
        }else{
          let questionario = this.formataDadosFormulario();
          this.$router.push({ name: 'revisar-questionario', params: { questionarioData: questionario }});
        }
        this.loadingPage = false;
      },

      formataDadosFormulario(){
        return {
          descricao:         this.form.questionario.nome,
          descricao_tecnica: this.form.questionario.descricao,
          usar_feedback:     this.form.questionario.usarFeedback,
          questoes:          this.form.questionario.questoes,
          qtdQuestoes:       this.form.questionario.qtdQuestoes
        };
      },

      verificaNomeEmUso(){
        this.erroNomeQuestionario = false;
        let rota = this.$helpers.preparaRotaComParametros(
          this.$api.questionario.verificaNomeExiste,
          "{nomeQuestionario}",
          this.form.questionario.nome
        );
        return this.$http.get(rota)
        .then((res) => {
          if (res.status !== 200) {
            this.erroNomeQuestionario = true;
          }
        }).catch((error) => {
          if (error.response.status === 400) {
            this.erroNomeQuestionario = true;
          }
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .title-radio-custom{
    font-size: 0.938rem!important;
  }
  .custom-information{
    font-size: 0.825rem;
    font-weight: 400;
    line-height: 15px;
    color: #B9B9C3;
  }
  .min-button{
    width: 1.625rem;
    height: 1.625rem;
    padding: 0;
    margin-top: 0!important;
    text-align: center;
  }
  .ml-custom{
    margin-left: 0.225rem;
  }
  .placeholder-black{
    font-size: 12px;
    font-weight: 400;
  }
  .placeholder-black::-webkit-input-placeholder{
    color: #5E5873;
  }
  .placeholder-black:-moz-placeholder { /* Firefox 18- */
    color: #5E5873;
  }
  .placeholder-black::-moz-placeholder {  /* Firefox 19+ */
    color: #5E5873;
  }
  .placeholder-black:-ms-input-placeholder {
    color: #5E5873;
  }
  .placeholder-bold{
    font-weight: 600 !important;
  }
  .custom-margin-top{
    margin-top: 0.375rem !important;
    background: black;
  }
  .info-pontuacao{
    margin-right: 0.425rem;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }
  .width-input-custom{
    width: 85%;
    @media (max-width: 990px) {
      width: 60%;
    }
  }
  .adjust-position{
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: 990px) {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
  .align-custom-buttons{
    display: flex;
    justify-content: center;
    @media (max-width: 500px) {
      flex-direction: column;
      justify-content: flex-start;

    }
  }
  .with-information-cora{
    width: 18px;
    height: 18px;
  }
</style>
